import React from 'react'

export default function Footer() {
  return (
    <>
    {/***********************************
      Footer start
  ************************************/}
  <div className="footer">
    <div className="copyright">
      <p>
        Copyright © Designed &amp; Developed by{" "}
        <a href="../index.htm" target="_blank">
         UnCodemy 
        </a>{" "}
        {new Date().getFullYear()}
      </p>
    </div>
  </div>
  {/***********************************
      Footer end
  ************************************/}
    
    </>
  )
}
