import React, { useState, useEffect, useContext } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MessageIcon from "@mui/icons-material/Message";
import { NavLink, useNavigate } from "react-router-dom";
import { adddata, deldata, updatedata } from "../context/ContextProvider";
import Swal from "sweetalert2";
import Header from "./Header";
import { StudentContext } from "../context/StudentState";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Link, useParams } from "react-router-dom";

export default function Home() {
  let ContextValue = useContext(StudentContext);

  document.title = "Registration Receipt - Home";

  const navigation = useNavigate();
  const navigate = useNavigate();

  let sameDateTime = [];
  let studentData = [];

 
  // const [searchQuery, setSearchQuery] = useState();
 
  const [user, setUser] = useState("student");
  const [currentStudent, setCurrentStudent] = useState();
  const [counselor, setCounselor] = useState();
  const [total, setTotal] = useState();
  const [newTotal, setNewTotal] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalrunningBatch, setTotalRunningBatch] = useState();
  const [register, setRegister] = useState();
  const [currentRegister, setCurrentRegister] = useState();
  const [allCourse, setAllCourse] = useState();
  const [allCourseLength, setAllCourseLength] = useState();
  const [course, setCourse] = useState();
  const [weekDaysBatch, setWeekDaysbatch] = useState();
  const [weekEndBatch, setWeekEndBatch] = useState();
  const [currentMonth, setCurrentMonth] = useState('');
  const [dataStatus, setDataStatus] = useState("month")

  const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]


  //All Trainer
  let tempCurrentStudent;
  const [getuserdata, setUserdata] = useState("");
  console.log("trainer");
  

  const getAllCourses = async () => {
    console.log("all course function");
    ContextValue.updateProgress(30);
    ContextValue.updateBarStatus(true);

    let allCourse = await ContextValue.getAllMainSubCourse();

    console.log("all course =", allCourse);
    setAllCourse(allCourse.allCourse);
    setAllCourseLength(allCourse.courses.length);
    setCourse(allCourse.courses);
    ContextValue.updateProgress(100);
    ContextValue.updateBarStatus(false);
  };

  const getAllCounselor = async () => {
    const counselorData = await ContextValue.getAllCounselor();

    if (counselorData.status === "active") {
      setCounselor(counselorData.counselorData)

    }
  }


  useEffect(() => {
    // fetchAdminStatus();



    setCurrentMonthFunc()
    // getRegisteredStudent();
    
    getAllCourses();
    getAllCounselor();
   
  }, []);

  const setCurrentMonthFunc = ()=>{
    
    const now = new Date();
    // Get the current month and year
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    // Set the value to the current month and year
    setCurrentMonth(`${year}-${month}`);
    getRegisteredStudentMonth(month)
  }

  const getRegisteredStudent = async()=>{
    ContextValue.updateProgress(30)
    ContextValue.updateBarStatus(true)

    let registeredStudent = await ContextValue.getRegisterStudent()
    setRegister(registeredStudent)
    setCurrentRegister(registeredStudent)

    ContextValue.updateProgress(100)
        ContextValue.updateBarStatus(false)
  }
  // get month wise data


  const getRegisteredStudentMonth = async(month)=>{
    ContextValue.updateProgress(30)
    ContextValue.updateBarStatus(true)

    let registeredStudent = await ContextValue.getRegisterStudentMonth(month)
    console.log("month wise student data =",registeredStudent)
    setRegister(registeredStudent)
    setCurrentRegister(registeredStudent)

    ContextValue.updateProgress(100)
        ContextValue.updateBarStatus(false)
  }



  const moveToRegisterStudent = () => {
    navigate("Registered-Student", { state: { registerStudent: register } });
  };
  const moveToAddStudent = () => {
    navigate("Add-Registered-Student");
  };

 

  const moveToAllCourses = () => {
    navigate("AllCourse", { state: { course: course, allCourse: allCourse } });
  };
  const moveToAllCounsellor = () => {
    navigate("AllCounsellor", { state: { counsellor: counselor } });
  };
 
  const setMonthFunc =(value)=>{

    setCurrentMonth(value)
    const month = value.split('-')[1]
    console.log("selected month value =",value,month)
    getRegisteredStudentMonth(month)

  }

  function processMonth(monthString) {
    // Remove leading zero and convert to number
    let monthNumber = parseInt(monthString, 10);
  
    // Decrement the value by one
    monthNumber -= 1;
  
    // Return the result as a string

    console.log("month string =",monthNumber.toString())
    return monthNumber.toString();

  }
  return (
    <>
      <Header />
      <div className="sidebar-main-container">
        {/* <Sidebar /> */}
        <div className="content-body">

<div className="d-flex flex-col align-items-center">

          <div>
            
           {/* <input type="month"/> */}

          <button className={`btn btn-primary mx-2 ${dataStatus==="month"?"":"btn-fade"}`} onClick={()=>{setDataStatus("month"); setCurrentMonthFunc()}}> Current Month</button>
      {dataStatus==="month" && <input type="month" id="monthInput" value={currentMonth} onChange={(e) => {setMonthFunc(e.target.value)}} />}

      <button className={`btn btn-primary mx-2 ${dataStatus==="all"?"":"btn-fade"}`} onClick={()=>{setDataStatus("all");getRegisteredStudent()}}> All Student</button>

          </div>
     {dataStatus==="month" && <h4 className="my-2">{monthName[processMonth(currentMonth.split('-')[1])]} Student</h4>}
     {dataStatus==="all" && <h4 className="my-2">All Student</h4>}
      </div>
          {/* row */}
          <div className="container-fluid">
            <div className="row total-detail-container">
              <div className="detail-card"> 

                <div className="col-xl-3 col-xxl-3 col-sm-6">
                  <div className="widget-stat card p-0 bg-secondary">
                    <div className="card-body" onClick={moveToAddStudent}>
                      <div className="media">
                        <span className="mr-3">
                        <i class="fa-solid fa-plus"/>
                        </span>
                        <div
                          className="media-body text-white"
                          
                        >
                          <p className="mb-1">Add Registration</p>
                          {/* <div className="progress mb-2 bg-white">
                        <div
                          className="progress-bar progress-animated bg-light"
                          style={{ width: "76%" }}
                        />
                      </div> */}
                          {/* <small>76% Increase in 20 Days</small> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  

                <div className="col-xl-3 col-xxl-3 col-sm-6">
              <div className="widget-stat card p-0 bg-secondary">
                <div className="card-body" onClick={moveToRegisterStudent}>
                  <div className="media">
                    <span className="mr-3">
                    <i class="fa-regular fa-address-card"/>
                    </span>
                    <div className="media-body text-white" >
                      <p className="mb-1">Total Registration</p>
                      <h3 className="text-white">{register && register.length}</h3>
                      {/* <div className="progress mb-2 bg-white">
                        <div
                          className="progress-bar progress-animated bg-light"
                          style={{ width: "76%" }}
                        />
                      </div> */}
                      {/* <small>76% Increase in 20 Days</small> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>  

            <div className="col-xl-3 col-xxl-3 col-sm-6">
                  <div className="widget-stat card p-0 bg-danger">
                    <div className="card-body">
                      <div className="media">
                        <span className="mr-3">
                          <i class="fa-regular fa-newspaper" />
                        </span>
                        <div
                          className="media-body text-white"
                          onClick={moveToAllCourses}
                        >
                          <p className="mb-1">All Course</p>
                          <h3 className="text-white">
                            {allCourseLength && allCourseLength}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
            <div className="col-xl-3 col-xxl-3 col-sm-6">
                  <div className="widget-stat card p-0 bg-danger">
                    <div className="card-body">
                      <div className="media">
                        <span className="mr-3">
                          <i class="fa-regular fa-newspaper" />
                        </span>
                        <div
                          className="media-body text-white"
                          onClick={moveToAllCounsellor}
                        >
                          <p className="mb-1">All Counsellor</p>
                          <h3 className="text-white">
                            {counselor && counselor.length}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              
              
              </div>
            
            </div>
          </div>
        </div>
      </div>

      {/***********************************
      Content body end
*/}
    </>
  );
}
