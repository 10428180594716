import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Home'
import Footer from './Footer'
import { useContext } from 'react';
import { StudentContext } from '../context/StudentState';
import StudentState from '../context/StudentState'
import './style.css'
import './responsive.css'
import RegisterStudentAdd from './Counselor/RegisterStudentAdd'
import AddRegisteredStudent from '../Students/AddRegisteredStudent'
import RegisterStudent from './RegisterStudent'
import AllCourse from './AllCourse'
import RegistrationReceipt from '../Students/RegistrationReceipt'
import AllCounsellor from './AllCounsellor';

// import Navbaar from './components/Navbaar';
export default function App() {
  let ContextValue = useContext(StudentContext);
  return (
      <BrowserRouter>
        <StudentState>
          <Routes>

           
            {/* <Route exact path='/' element={<LogIn />} /> */}
            <Route exact path='/' element={<Home />} />
            <Route exact path='/Add-Registered-Student' element={<RegisterStudentAdd/>} />
            <Route exact path='Registered-Student/Add-Registered-Student' element={<AddRegisteredStudent/>} />
            <Route exact path='/AllCourse' element={<AllCourse />} />
            <Route exact path='/AllCounsellor' element={<AllCounsellor/>} />
            <Route exact path='/Registered-Student' element={<RegisterStudent />} />
            <Route exact path="Add-Registered-Student/registrationReceipt" element={<RegistrationReceipt/>} />
            {/* <Route exact path='/' element={<RegistartionHome />} /> */}
            
          </Routes>
          <Footer />
        </StudentState >
      </BrowserRouter >
     

  )
}
