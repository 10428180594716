import React, { useEffect, useContext, useState } from 'react'
import { Link, useNavigate, useLocation, json  } from 'react-router-dom'
import Header from './Header';
import { StudentContext } from '../context/StudentState';
import Swal from 'sweetalert2'

const AllCounsellor = () => {

    const location = useLocation();
    let {counsellor} = location.state

    let ContextValue = useContext(StudentContext);

    const addCounsellor = (mainCourse)=>{

        console.log('sub course =',mainCourse)
        Swal.fire({
            title: 'Add Counsellor Details',
            html:
                '<input id="CounsellorName" class="swal2-input" placeholder="Counsellor Name">' +
                '<input id="Contact" class="swal2-input" placeholder=" Contact">'+
                '<input id="CounsellorCode" class="swal2-input" placeholder=" CounsellorCode">',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Add',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
    
              const CounsellorName = document.getElementById('CounsellorName').value;
              const Contact = document.getElementById('Contact').value;
              const CounsellorCode = document.getElementById('CounsellorCode').value;

              let counsellorDetails  = {Name:CounsellorName,counselorReference:CounsellorCode,Number:Contact}
    
                addNewCounsellor(counsellorDetails)
              Swal.fire({
                title: `${result.value}`,
                
                imageUrl: result.value.avatar_url
              })
            }
          })
      }

      const addNewCounsellor = async(counsellorDetails)=>{
        console.log("counsellor data =",counsellorDetails)

        ContextValue.updateProgress(30)
    ContextValue.updateBarStatus(true)

    try{
        let newCounsellor = await fetch('http://blockey.in:8001/addNewCounsellor',{
          method:"POST",
          headers:{
            "Content-Type": "application/json",
          },
          body:JSON.stringify(counsellorDetails)
        })

        newCounsellor = await newCounsellor.json()
        ContextValue.updateProgress(100)
        ContextValue.updateBarStatus(false)
        console.log("new cousnellor=",newCounsellor)

        if(newCounsellor.status==true){
         
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Counsellor Added',
            showConfirmButton: false,
            timer: 1500
          })
        }

        else if(newCounsellor.status=="code exist"){
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: `${newCounsellor.message}`,
            showConfirmButton: false,
            timer: 3000
          })
        }
        else{
          Swal.fire({   
            icon:  'error',
            title: 'Oops...',
            text:  'Something Went Wrong',
          }) 
        }

      }
      catch(error){
        Swal.fire({   
          icon:  'error',
          title: 'Oops...',
          text:  'Something Went Wrong',
        }) 
        ContextValue.updateProgress(100)
          ContextValue.updateBarStatus(false)
      }

      }

      const deleteCounsellor = async (counsellor) => {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: `Do you want to delete the counsellor: ${counsellor}?`,
          icon: "warning",
          showCancelButton: true, // This shows the "Cancel" button
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel'
        });
      
        if (result.isConfirmed) {
          console.log("delete counsellor =", counsellor);
      
           try{
        let deleteCounsellor = await fetch('http://blockey.in:8001/deleteCounsellor',{
          method:"DELETE",
          headers:{
            "Content-Type": "application/json",
            "id":counsellor._id
          },
        })

        deleteCounsellor = await deleteCounsellor.json()
        ContextValue.updateProgress(100)
        ContextValue.updateBarStatus(false)
        

        if(deleteCounsellor.status==true){
         
         Swal.fire("Deleted!", "The counsellor has been deleted.", "success");
        }
        else{
          Swal.fire({   
            icon:  'error',
            title: 'Oops...',
            text:  'Something Went Wrong',
          }) 
        }

      }
      catch(error){
        Swal.fire({   
          icon:  'error',
          title: 'Oops...',
          text:  'Something Went Wrong',
        }) 
        ContextValue.updateProgress(100)
          ContextValue.updateBarStatus(false)
      }
      
          
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your counsellor is safe.", "error");
        }
      };
      

  return (
   <>

   <Header/>
   <div className='sidebar-main-container'>

   
       
       <div className="content-body register-content-body">

      
         {/* row */}
         <div className="container-fluid">
         <div className="row page-titles mx-0">
              <div className="col-sm-6 p-md-0">
                <div className="welcome-text">
                  <h4>All Counsellor</h4>
                </div>
              </div>


             
            </div>
          <button className='btn btn-primary my-2' onClick={e=>addCounsellor()}>Add New Counsellor</button>
           
           <div className="row">
             <div className="col-lg-12">

             </div>
             <div className="col-lg-12">
               <div className="row tab-content w-full">
                 <div id="list-view" className="tab-pane fade active show col-lg-12">
                   <div className="card">
                     <div className="card-header">
                       <h4 className="card-title">Counsellor List</h4>

                     </div>


                     <div class="">


                       <div class="row">

                         <div class="col-md-12">


                         <div class="table-responsive recentOrderTable __web-inspector-hide-shortcut__">
                            <table id="datatable" class="table table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                <tr>
                                 
                                  <th>Counsellor No.</th>
                                  <th>Name</th>
                                  <th>Contact</th>
                                  <th>Counsellor Reference</th>
                                  <th>Delete</th>
                                 

                                </tr>
                              </thead>

                              <tbody>
                                {counsellor && counsellor.map((data, index) => {

                                  return (
                                    <tr>

                                      <td>{data.counselorNo}</td>
                                      <td>{data.Name}</td>
                                      <td>{data.Number}</td>
                                      <td>{data.counselorReference}</td>
                                      <td><i class="fa-solid fa-trash" onClick={e=>{deleteCounsellor(data)}}/></td>
                                      
                                    </tr>
                                  )
                                })
                                }

                              </tbody>
                            </table>
                            </div>


                         </div>
                       </div>
                     </div>










                   </div>
                 </div>

               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   
   </>

    
  )
}

export default AllCounsellor